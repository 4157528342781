/** @section Brand */

.brand {
	display: inline-block;

	.brand-logo-light {
		display: none;
	}

	.brand-logo-dark {
		display: block;
	}
}
