/** @section Boxes */

// Box Minimal
.box-minimal {
	text-align: center;

	.box-minimal-icon {
		font-size: 50px;
		line-height: 50px;
		color: $primary;
	}
}

.box-minimal-divider {
	width: 36px;
	height: 4px;
	margin-left: auto;
	margin-right: auto;
	background: $primary;
}

.box-minimal-text {
	width: 100%;
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

* + .box-minimal {
	margin-top: 30px;
}

* + .box-minimal-title {
	margin-top: 10px;
}

* + .box-minimal-divider {
	margin-top: 20px;
}

* + .box-minimal-text {
	margin-top: 15px;
}

%context-dark {
	.box-minimal {
		p {
			color: $body-color;
		}
	}
}


// Box Counter
.box-counter {
	position: relative;
	text-align: center;
	color: $gray-700;
}

.box-counter-title {
	display: inline-block;
	font-family: $font-family-sec;
	font-size: 16px;
	line-height: 1.3;
	letter-spacing: -.025em;
}

.box-counter-main {
	font-family: $font-family-sec;
	font-size: 45px;
	font-weight: 400;
	line-height: 1.2;

	> * {
		display: inline;
		font: inherit;
	}

	.small {
		font-size: 28px;
	}

	.small_top {
		position: relative;
		top: .2em;
		vertical-align: top;
	}
}

.box-counter-divider {
	font-size: 0;
	line-height: 0;
	&::after {
		content: '';
		display: inline-block;
		width: 80px;
		height: 2px;
		background: $primary;
	}
}

* + .box-counter-title {
	margin-top: 10px;
}

* + .box-counter-main {
	margin-top: 20px;
}

* + .box-counter-divider {
	margin-top: 10px;
}

@include media-breakpoint-up(md) {
	.box-counter-title {
		font-size: 18px;
	}
}

@include media-breakpoint-up(xl) {
	.box-counter-main {
		font-size: 60px;

		.small {
			font-size: 36px;
		}
	}

	* + .box-counter-main {
		margin-top: 30px;
	}
	* + .box-counter-divider {
		margin-top: 15px;
	}
	* + .box-counter-title {
		margin-top: 18px;
	}
}

.bg-primary {
	.box-counter {
		color: $white;
	}

	.box-counter-divider {
		&::after {
			background: $white;
		}
	}
}

// Box List

.box-list {
	display: flex;
}

.box-list-counter {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 60px;
	background: $secondary;
	position: relative;
	transition: .3s all ease;
	margin-right: 10px;
	flex-shrink: 0;
	flex-grow: 1;
	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		margin-right: 60px;
	}
	&::before {
		// Medium ≥576px
		@include media-breakpoint-up(sm) {
			content: '';
			position: absolute;
			top: 0;
			right: -30px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 30px 0 30px 30px;
			border-color: transparent transparent transparent $secondary;
			transition: .3s all ease;
		}
	}
	&::after {
		content: attr(data-letter);
		position: absolute;
		left: 10px;
		// Medium ≥576px
		@include media-breakpoint-up(sm) {
			left: 18px;
			.ie-10 &,
			.ie-11 &,
			.ie-edge & {
				top: 16px;
			}
		}
	}
}



.box-list-text {
	width: calc(100% - 44px);
}

.box-list:hover {
	.box-list-counter {
		background: $primary;
		transition: .3s all ease;
		&::before {
			border-color: transparent transparent transparent $primary;
			transition: .3s all ease;
		}
	}
}


// Box Nina

.box-nina {
	display: flex;
}

.box-nina-icon {
	margin-right: 40px;
}

// Box Rita

.box-rita {
	display: flex;
	flex-direction: column;
	// Medium ≥576px
	@include media-breakpoint-up(sm) {
	  flex-direction: row;
	}
}

.box-rita-media {
	flex-shrink: 0;
	flex-grow: 1;
	text-align: center;

	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		margin-right: 30px;
		text-align: left;
	}
}

.box-rita-text {
	text-align: center;
	margin-top: 25px;
	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		margin-top: 0;
		text-align: left;
	}
}

// Custom Box

.box-1 {
	@include media-breakpoint-down(sm) {
		max-width: 370px;
		margin-right: auto;
		margin-left: auto;
	}
}

.box-2 {
	@include media-breakpoint-down (md) {
		max-width: 370px;
		margin-right: auto;
		margin-left: auto;
	}
}