/** @section Owl Carousel */


// Local variables
$owl-base: $gray-800;
$owl-primary: $primary;


// Core styles (editing is not recommended)
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden; /* fix firefox animation glitch */
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
		transform: translate3d(0px, 0px, 0px);
	}

	.owl-wrapper,
	.owl-item {
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}
	.owl-item img {
		display: block;
		width: 100%;
	}

	.owl-nav,
	.owl-dots {
		-webkit-tap-highlight-color: transparent;

		&.disabled {
			display: none;
		}
	}

	.owl-prev,
	.owl-next,
	.owl-dot {
		cursor: pointer;
		user-select: none;
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
	}

	&.owl-loaded {
		display: block;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		visibility: hidden;
	}

	&.owl-drag .owl-item {
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&.owl-grab {
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;
	}

	&.owl-rtl .owl-item {
		float: right;
	}
}


// Theme styles
.owl-carousel {
	// Next and Prev buttons
	.owl-nav {
		pointer-events: none;
	}

	.owl-prev,
	.owl-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 3px;
		font-size: 40px;
		line-height: 1;
		cursor: pointer;
		pointer-events: auto;
		background: $owl-primary;
		color: $owl-base;

		&::before,
		&::after {
			display: block;
			font-family: $fa;
		}

		&:hover {
			text-decoration: none;
			background: $owl-base;
			color: $owl-primary;
		}

		&.disabled {
			opacity: .5;
			cursor: default;
		}
	}

	.owl-prev {
		left: 10px;
	
		&::before {
			content: '\f104';
		}
	}

	.owl-next {
		right: 10px;
	
		&::before {
			content: '\f105';
		}
	}

	// Dots
	.owl-dots {
		text-align: center;
	}

	.owl-dot {
		display: inline-block;
		zoom: 1;
		margin: 0 5px;

		span {
			width: 10px;
			height: 10px;
			background: $owl-base;
			display: block;
			-webkit-backface-visibility: visible;
			transition: opacity 200ms ease;
			border-radius: 5px;
		}

		&.active,
		&:hover {
			span {
				background: $owl-primary;
			}
		}
	}
}
