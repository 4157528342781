/** @section Images */

// Standard img style
img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-responsive {
	width: 100%;
}
