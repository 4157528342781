/** @section Progress Bars */


// Linear Progress Bars
.progress-linear {
	position: relative;
	text-align: left;
}

.progress-linear-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include spacing(10px);
}

.progress-linear-body {
	height: 10px;
	background: $gray-200;
}

.progress-linear-bar {
	width: 0;
	height: inherit;
	background: $primary;
	transition: 1s all ease-in-out;
}

.progress-linear-counter {
	font-weight: 700;
	color: $gray-300;

	&::after {
		content: "%";
	}
}

* + .progress-linear-body {
	margin-top: 8px;
}

.progress-linear + .progress-linear {
	margin-top: 15px;
}

@include media-breakpoint-up(lg) {
	.progress-linear + .progress-linear {
		margin-top: 25px;
	}
}
