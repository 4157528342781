/** @section Offsets */

.offset-top-15 {
	margin-top: 15px;
}

.offset-top-20 {
	margin-top: 20px;
}

.offset-top-25 {
	margin-top: 25px;
}

.offset-top-30 {
	margin-top: 30px;
}

.offset-top-40 {
	margin-top: 40px;
}

.offset-top-40-md-30 {
	margin-top: 30px;
	// Medium ≥768px
	@include media-breakpoint-up(md) {
		margin-top: 40px;
	}

}

// Elements offset
* + p {
	margin-top: 15px;
}

// Headings + Paragraph
h2 + p,
.heading-2 + p {
	margin-top: 18px;
}

h4 + p,
.heading-4 + p {
	margin-top: 22px;
}

// Paragraph + Headings
p + h2,
p + .heading-2 {
	margin-top: 15px;
}

p + h4 {
	margin-top: 32px;
}

// Tags + Tags
p + p,
p + ul,
p + ol {
	margin-top: 12px;
}

img + p {
	margin-top: 15px;
}

h3 + img {
	margin-top: 42px;
}

a + h4 {
	margin-top: 22px;
}

img + h4 {
	margin-top: 32px;
}

// Classes
* + .row:not(.offset-top-0) {
	margin-top: 30px;
}

* + .big {
	margin-top: 20px;
}

* + .text-block {
	margin-top: 30px;
}

* + .button {
	margin-top: 30px;
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		margin-top: 44px;
	}
}

* + .link-secondary {
	margin-top: 20px;
}

// Classes + Tags
.row + h3,
.row + .heading-3 {
	margin-top: 80px;
}

// Classes + Classes
.container + .container {
	margin-top: 35px;
}

.row + .row {
	margin-top: 35px;
}

.box-list + .box-list {
	margin-top: 30px;
}

.box-nina + .box-nina {
	margin-top: 25px;
}

// Media offsets
@include media-breakpoint-up(xl) {
	* + .rd-form {
		margin-top: 30px;
	}

	.container + .container {
		margin-top: 60px;
	}
	.row + .row {
		margin-top: 60px;
	}
}

// Inset Block

.inset-block-1 {
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		padding-right: 50px!important;
	}
	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
		padding-right: 0!important;
	}
}