/**
*
* RD Navbar Classic
* =====================================================
*/
.rd-navbar-classic {
	// Static
	&.rd-navbar-static {
		box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.21);


		.rd-navbar-aside-outer,
		.rd-navbar-main-outer {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}

		.rd-navbar-aside,
		.rd-navbar-main {
			max-width: $rd-navbar-static-width;
			margin-left: auto;
			margin-right: auto;
			// Mega large ≥1600px
			@include media-breakpoint-up(xxl) {
				padding: 0 15px;
			}
		}

		.rd-navbar-aside {
			padding: 12px 0;
			// Mega large ≥1600px
			@include media-breakpoint-up(xxl) {
				padding: 12px 15px;
			}
		}

		.rd-navbar-main {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			/*padding: 30px 0;*/
		}

		// RD Navbar Brand
		.rd-navbar-brand {
			img {
				width: 100%;
				height: auto;
				max-width: 200px;
				max-height: 200px;
			}
		}

		.rd-navbar-main-element {
			display: flex;
			align-items: center;
			z-index: 12;
		}

		&.rd-navbar--is-stuck,
		&.rd-navbar--is-clone {
			border-bottom-color: nth($rd-navbar-border, 3);
			.rd-navbar-aside-outer {
				display: none;
			}

			.rd-navbar-main {
				/*padding: 17px 0;*/

			}

			.rd-navbar-nav-item {
				> .rd-navbar-submenu {
					margin-top: 17px;
				}
			}
			.rd-nav-item .rd-nav-link {
				padding-top: 26px;
				padding-bottom: 20px;
			}
		}
	}

	// Fixed 
	&.rd-navbar-fixed {
		.rd-nav-link {

		}
	}
}
