.product-logo {
	display: block;
	position: relative;
    overflow: hidden;
    padding: 0px 10px;

	.product-image {
		width: 100%;
		height: auto;
		padding-top: 75%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
}