.skew-right  {
	position: relative;
	z-index: 1;
	&::before {
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 5000px;
			height: 100%;
			z-index: -1;
			transform: skewX(-23.6deg);
		}
	}
}


.skew-left {
	position: relative;
	z-index: 1;
	&:before {
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 5000px;
			height: 100%;
			z-index: -1;
			@include transform(skewX(23.6deg));
		}
	}
}

.overlay-bg-1 {
	position: relative;
	> * {
		position: relative;
		z-index: 2;
	}
	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($gray-750, .7);
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}

.overlay-bg-2 {
	position: relative;
	> * {
		position: relative;
		z-index: 2;
	}
	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($gray-750, .2);
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}

.overlay-bg-3 {
	position: relative;
	> * {
		position: relative;
		z-index: 2;
	}
	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba($primary, .8);
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}