/** @section Thumbnails */

// Figure Light
.figure-light figcaption {
	padding-top: 10px;
	color: $gray-400;
}

/*==================     Thumb     ======================*/

.thumb-wrapper {
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
}


.thumb {
	position: relative;
	display: block;
	overflow: hidden;

	> img {
		width: 100%;
		transition: 1s all ease-out;
		@include transform-origin(100% 0);
		.ie-10 &,
		.ie-11 &,
		.ie-edge & {
			@include transform-origin(100%);
		}
	}

	&-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&:before, &:after {
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			border: 6px solid transparent;
			opacity: 0;
			transition: 1s all ease;
		}

		&:before {
			left: 0;
			top: 0;
			border-top-color: $primary;
			border-left-color: $primary;
		}

		&:after {
			right: 0;
			bottom: 0;
			border-right-color: $primary;
			border-bottom-color: $primary;
		}
	}

	&:hover {
		.thumb-overlay {
			&:before, &:after {
				width: 100%;
				height: 100%;
				opacity: 1;
			}
		}

		img {
			transition: 10s all ease-in;
			@include transform(scale(1.2));
			.ie-10 &,
			.ie-11 &,
			.ie-edge & {
				@include transform(scale(1));
			}
		}
	}
}