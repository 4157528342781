// Links
@mixin link($color: $body-color, $color-hover: $primary) {
	&,
	&:focus,
	&:active {
		color: $color;
	}
	&:hover {
		color: $color-hover;
	}
}

@mixin text-var($color, $hover-color) {
	&#{&} {
		color: $color;
	}
	@at-root  a#{&}#{&} {
		@include link($color, $hover-color)
	}
}

.link-1 {
	color: $primary;
	&:hover {
		color: rgba($primary, .6);
	}
}

.link-2 {
	color: $primary;
	&:hover {
		text-decoration: underline;
	}
}

.link-3 {
	color: $body-color;
	&:hover {
		color: $primary;
	}
}

.link-secondary {
	position: relative;
	display: inline-block;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: $white;
	padding-right: 25px;
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		font-size: 20px;
	}

	&:first-letter {
		text-transform: uppercase;
	}
	&::before {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		height: 2px;
		width: 80%;
		background: $white;
	}
	&::after {
		content: "\f105";
		position: absolute;
		right: 15px;
		top: 50%;
		font-family: $fa;
		font-size: 26px;
		color: $primary;
		line-height: 22px;
		@include transform(translate(13px, -50%));
		transition: .3s all ease;
		// Extra large ≥1200px
		@include media-breakpoint-up(xl) {
			font-size: 26px;
		}
	}

	&:hover {
		color: inherit;
		&:after {
			@include transform(translate(16px, -50%));
		}
	}
}