/** @section Lists */

// Vertical list
.list {
	> li + li {
		margin-top: 10px;
	}
}

.list-xs > li + li {
	margin-top: 5px;
}

.list-sm > li + li {
	margin-top: 10px;
}

.list-md > li + li {
	margin-top: 18px;
}

.list-lg > li + li {
	margin-top: 25px;
}

.list-xl > li + li {
	margin-top: 30px;
}

@include media-breakpoint-up(md) {
	.list-xl > li + li {
		margin-top: 60px;
	}
}


// List inline
.list-inline {
	> li {
		display: inline-block;
	}
}

html {
	.list-inline-md {
		@include spacing(20px, 8px);

		@include media-breakpoint-up(lg) {
			@include spacingX(30px);
		}
	}
	.list-inline-md-2 {
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}
}


// List terms
.list-terms {
	dt + dd {
		margin-top: 5px;
	}
	dd + dt {
		margin-top: 25px;
	}
}

* + .list-terms {
	margin-top: 25px;
}


// Index list
.index-list {
	counter-reset: li;

	> li {
		.list-index-counter {
			&:before {
				content: counter(li, decimal-leading-zero);
				counter-increment: li;
			}
		}
	}
}


// Marked list
.list-marked {
	text-align: left;

	> li {
		position: relative;
		padding-left: 25px;

		&::before {
			position: absolute;
			display: inline-block;
			left: 0;
			top: 1px;
			min-width: 25px;
			content: '\f105';
			font: 400 14px/24px 'FontAwesome';
			color: $gray-400;
		}
	}

	> li + li {
		margin-top: 4px;
	}
}

* + .list-marked {
	margin-top: 15px;
}

p + .list-marked {
	margin-top: 10px;
}


// Ordered List
.list-ordered {
	counter-reset: li;
	text-align: left;

	> li {
		position: relative;
		padding-left: 25px;

		&:before {
			position: absolute;
			content: counter(li, decimal) '.';
			counter-increment: li;
			top: 0;
			left: 0;
			display: inline-block;
			width: 15px;
			color: $gray-400;
		}
	}

	> li + li {
		margin-top: 10px;
	}
}

* + .list-ordered {
	margin-top: 15px;
}

/*==================  Marked List  ======================*/

.marked-list {
	text-align: left;

	> li {
		position: relative;
		padding-left: 30px;



		&:before {
			content: "\f105";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-weight: 400;
			font-size: 26px;
			font-family: $fa;
			color: $primary;
		}

		& + li {
			margin-top: 22px;
			// Extra large ≥1200px
			@include media-breakpoint-up(xl) {
				margin-top: 16px;
			}
		}

		> a:hover {
			color: $primary;
		}
	}
}

.marked-list-2 {
	max-width: 322px;
	margin-right: auto;
	margin-left: auto;
	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		max-width: 100%;
		margin-right: 0;
		margin-left: 0;
	}
	> li {
		position: relative;
		padding-left: 30px;



		&:before {
			content: "\f105";
			position: absolute;
			left: 0;
			top: 16px;
			font-weight: 400;
			font-size: 26px;
			font-family: $fa;
			color: $primary;
		}

		& + li {
			margin-top: 22px;
			// Extra large ≥1200px
			@include media-breakpoint-up(xl) {
				margin-top: 16px;
			}
		}

		> a:hover {
			color: $primary;
		}
	}

}

.marked-list-3 {
	text-align: left;

	> li {
		position: relative;
		padding-left: 30px;



		&:before {
			content: "\f105";
			position: absolute;
			left: 0;
			top: -8px;
			font-weight: 400;
			font-size: 26px;
			font-family: $fa;
			color: $primary;
		}

		& + li {
			margin-top: 22px;
			// Extra large ≥1200px
			@include media-breakpoint-up(xl) {
				margin-top: 16px;
			}
		}

		> a:hover {
			color: $primary;
		}
	}
}

/*==================  Contact List  ======================*/

.contact-list {
	.contact-list-text {
		display: inline-block;
		min-width: 90px;
	}
}

