/** @section Animate */


.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	opacity: 1;
}

.animated.infinite {
	animation-iteration-count: infinite;
}

.animated.hinge {
	animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
	opacity: 0;
}


@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	animation-name: fadeIn;
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInDown {
	animation-name: fadeInDown;
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInLeft {
	animation-name: fadeInLeft;
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.fadeInRight {
	animation-name: fadeInRight;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.fadeOut {
	animation-name: fadeOut;
}

@keyframes slideInDown {
	0% {
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInDown {
	animation-name: slideInDown;
}

@keyframes slideInLeft {
	0% {
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	animation-name: slideInLeft;
}

@keyframes slideInRight {
	0% {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	animation-name: slideInRight;
}

@keyframes slideOutDown {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		visibility: hidden;
		transform: translate3d(0, 100%, 0);
	}
}

.slideOutDown {
	animation-name: slideOutDown;
}
