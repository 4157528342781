/** @section Backgrounds */

.context-dark {
	@extend %context-dark;
}

%context-dark {
	// Logo
	.brand {
		.brand-logo-dark {
			display: none;
		}

		.brand-logo-light {
			display: block;
		}
	}
}

%context-light {
	// Logo
	.brand {
		.brand-logo-dark {
			display: block;
		}

		.brand-logo-light {
			display: none;
		}
	}
}


// Light Backgrounds
.bg-default {
	@include bg-behaviour($body-bg);
}

.bg-gray {
	@include bg-behaviour($gray-100);
}


.bg-gray-100 {
	@include bg-behaviour($gray-100);
}


// Dark Backgrounds
.bg-gray-700 {
	@extend %context-dark;
	@include bg-behaviour($gray-700);
}

.bg-gray-900 {
	@extend %context-dark;
	@include bg-behaviour($gray-900);
}


// Accent Backgrounds



// Background Image
.bg-image {
	background-size: cover;
	background-position: center;
}

.bg-image-1 {
	background: url(../images/dark-pattern.jpg);
	background-repeat: repeat;
}

.bg-image-1-lg {
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		background: url(../images/dark-pattern.jpg);
		background-repeat: repeat;
	}
}

.bg-secondary {
	background: url(../images/dark-pattern.jpg) repeat;
	&.skew-left,
	&.skew-right {
		background-image: none;
		&:before {
			background: url(../images/dark-pattern.jpg) repeat;
			opacity: .85;
		}
	}
}


	// Desktop only
html:not(.tablet):not(.mobile) {
	.bg-fixed {
		@include media-breakpoint-up(lg) {
			background-attachment: fixed;
		}
	}
}

