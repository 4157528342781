/** @section Sections */

// Section Spacing
.section-xs {
	padding: 25px 0;
}

.section-sm,
.section-md,
.section-lg {
	padding: 50px 0;
}

.section-xl {
	padding-top: 50px;
	padding-bottom: 70px;
}

.section-first {
	padding-top: 50px;
	padding-bottom: 100px;
}

// Section collapse
.section-collapse + .section-collapse {
	padding-top: 0;
}

.section-collapse:last-child {
	padding-bottom: 0;
}

// Section with bottom zero padding
html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

@include media-breakpoint-down(md) {
	section.section-sm,
	section.section-md,
	section.section-lg,
	section.section-xl,
	section.section-xxl {
		&:first-of-type {
			padding-top: 40px;
		}
	}
}

@include media-breakpoint-up(md) {
	.section-sm {
		padding: 60px 0;
	}

	.section-md {
		padding: 70px 0;
	}

	.section-lg {
		padding: 85px 0;
	}
}

@include media-breakpoint-up(lg) {
	.section-md {
		padding: 80px 0;
	}

	.section-lg {
		padding: 100px 0;
	}
}

@include media-breakpoint-up(xl) {
	.section-lg {
		padding: 115px 0;
	}
	.section-xl {
		padding-top: 120px;
		padding-bottom: 112px;
	}
	.section-first {
		padding-top: 120px;
		padding-bottom: 112px;
	}
}

// Section single
.section-single {
	display: flex;
	text-align: center;

	p {
		margin-left: auto;
		margin-right: auto;
	}

	* + .rights {
		margin-top: 35px;
	}

	@include media-breakpoint-up(lg) {
		* + .rights {
			margin-top: 60px;
		}
	}

	.rd-mailform-wrap {
		max-width: 670px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	* + .rd-mailform-wrap {
		margin-top: 20px;
	}
}

.section-single-header {
	padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
	padding: calc(1em + 4vh) 0;
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 calc(1em + 3vh);
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;

	> * {
		width: 100%;
	}
}
