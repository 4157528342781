/** @section Material Parallax */


.parallax-container {
	position: relative;
	overflow: hidden;
}

.material-parallax {
	position: absolute;
	top: 0;
	left: -1px;
	right: -1px;
	bottom: 0;
	z-index: 0;
}

.parallax-content {
	position: relative;
	z-index: 1;
}

.material-parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	min-width: 101%;
	min-height: 101%;
	max-width: none;
	transform: translate3d(-50%, 0, 0);
}

.parallax-disabled {
	background-size: cover;
	background-position: center;

	html:not(.ie-11):not(.ios) & {
		background-attachment: fixed;
	}
}


// Custom Parallax

.custom-parallax {

	.bg-primary {
		background-color: $primary;
		.skew-left {
			background-color: transparent;

			&:before{
				background: rgba($primary, .8);
			}
		}
	}

	.bg-primary.skew-left,
	.bg-primary.skew-right {
		background-color: transparent;

		&:before{
			background: rgba($primary, .8);
		}
	}
}