/** @section Context Styles */

%context-dark {
	&,
	#{headings()} {
		color: $white;
	}
}

%context-light {
	color: $body-color;

	#{headings()} {
		color: $headings-color;
	}
}
