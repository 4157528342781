/** @section Row spacing */

.row-0 {
	@include grid-offset(0px);
}

.row-10 {
	@include grid-offset(10px);
}

.row-15 {
	@include grid-offset(15px);
}

.row-20 {
	@include grid-offset(20px);
}

.row-25 {
	@include grid-offset(25px);
}

.row-30 {
	@include grid-offset(30px);
}

.row-40 {
	@include grid-offset(40px);
}

.row-50 {
	@include grid-offset(50px);
}

@include media-breakpoint-up(lg) {
	.row-md-30 {
		@include grid-offset(30px);
	}
}
