/** @section Typography */

// Body
body {
	font-family: $font-family-base;
	font-size: $font-size-body;
	line-height: $line-height-base;
	font-weight: $font-weight-base;
	color: $body-color;
	background-color: $body-bg;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}

// Headings
#{headings()} {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: $headings-font-weight;
	color: $headings-color;

	a {
		color: inherit;

		&:hover {
			color: $primary;
		}
	}
}

h1,
.heading-1 {
	font-size: 32px;
	line-height: 1.5;

	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}
}

h2,
.heading-2 {
	font-size: 30px;
	line-height: 1.2;
	font-weight: 600;

	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
	}
}

h3,
.heading-3 {
	font-size: 24px;
	line-height: 1.5;

	@include media-breakpoint-up(xl) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}
}

h4,
.heading-4 {
	font-size: 22px;
	line-height: 1.5;
	font-weight: 600;

	@include media-breakpoint-up(xl) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5,
.heading-5 {
	font-size: 18px;
	line-height: 1.5;
	font-weight: 500;

	@include media-breakpoint-up(xl) {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
	}
}

h6,
.heading-6 {
	font-size: 18px;
	line-height: 1.5;

	@include media-breakpoint-up(xl) {
		font-size: $h6-font-size;
		line-height: $h6-line-height;
	}
}

// Emphasis
small,
.small {
	display: block;
	font-size: $font-size-sm;
	line-height: $line-height-sm;
}

mark,
.mark {
	padding: 3px 5px;
	color: $white;
	background: $primary;
}

// Big text
.big {
	font-size: $font-size-lg;
	line-height: $line-height-lg;
}

.lead {
	font-size: $lead-font-size;
	line-height: $lead-line-height;
	font-weight: $lead-font-weight;
}

code {
	padding: $code-padding-y $code-padding-x;
	border-radius: $border-radius-sm;
	font-size: $code-font-size;
	color: $code-color;
	background: $code-bg;
}

// Paragraph
p {
	[data-toggle='tooltip'] {
		padding-left: .25em;
		padding-right: .25em;
		color: $primary;
	}

	[style*='max-width'] {
		display: inline-block;
	}
}

// Selection
::selection {
	background: $primary;
	color: $white;
}

::-moz-selection {
	background: $primary;
	color: $white;
}

// Custom Opacity

.opacity-85 {
	opacity: .85;
}