/** @section Dividers */

hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: $hr-border-width solid $hr-border-color;
}

.divider {
	font-size: 0;
	line-height: 0;

	&::after {
		content: '';
		display: inline-block;
		width: 60px;
		height: 2px;
		background-color: $primary;
	}
}
