/*==================    Product    ======================*/
.product {
	display: block;
	position: relative;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
		transition: 1s all ease-out;
		@include transform-origin(100% 0);
	}

	.product-body {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;

		> h4 {
			padding: 17px 50px 17px 28px;
		}
	}

	.product-body-angle {
		position: absolute;
		top: 0;
		right: 0;
		width: 64px;
		height: 100%;

		&-top, &-bottom {
			position: absolute;
			height: calc(50% + 1px);
			width: 100%;
			right: -18px;
			border-left: 8px solid $primary;
			overflow: hidden;
			transition: .3s all ease;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 18px;
				width: 100%;
				height: 100%;
				background: url(../images/light-pattern.jpg) repeat;
			}
		}

		&-top {
			top: 0;
			@include transform(skewX(45deg));

			&:before {
				@include transform(skewX(-45deg));
				background-position: 0 bottom;
			}
		}

		&-bottom {
			bottom: 0;
			@include transform(skewX(-45deg));

			&:before {
				@include transform(skewX(45deg));
				background-position: 0 top;
			}
		}
	}

	&:hover {
		img {
			transition: 10s all ease-in;
			@include transform(scale(1.2));
		}

		.product-body-angle-top,
		.product-body-angle-bottom {
			border-left-width: 19px;
		}
	}
}